<script>
import AppInput from "@/components/ui/AppInput.vue";
import AppCheckbox from "@/components/ui/AppCheckbox.vue";
import AppDropDown from "@/components/ui/AppDropDown.vue";
import AppButton from "@/components/ui/AppButton.vue";
import { validPhone } from "@/utils/validPhone";

import { order_request_form_new } from "../jsons/order-request-form-new";

export default {
  name: 'MainInfoForm',

  props: {
    rate: { type: [Array, Object], required: true },
    storeData: { Object, default: null },
  },

  components: {
    AppInput,
    AppCheckbox,
    AppDropDown,
    AppButton
  },

  data() {
    return {
      elements: order_request_form_new.extras,
      autodelivery: order_request_form_new.auto_delivery,
      warehousData: [],
      cities_to: [],
      routerInfo: [],
      deliveryAccess: null,
      valid: false,
      delivery_to_error: null,
      select_city: false,

      selected_warehouse: [],

      formdata: {
        is_vtt: false,
        is_hbl: true,
        is_party: false,
        is_broker: false,
        is_car: false,
        is_exw: false,
        is_protected: false,
        is_inshurance: false,
        cont_cnt: null,
        warehouse_name: '',
        warehouse_address: '',
        warehouse_contact: '',
        warehouse_phone: '',
        warehouse_recip_name: '',

        delivery_from: "",
        delivery_to: "",

        pickup_address: null,
        isCreatingNew: false,
      }
    }
  },

  watch: {
    storeData: {
      handler() {
        this.fillFromStore()
      },
      deep: true,
    },
  },

  computed: {
    filteredObj() {
      return Object.fromEntries(
        Object.entries(this.autodelivery).filter(([key]) => key !== 'warehouse_address')
      );
    },
  },

  mounted() {
    this.getWarehouseData()
    if (this.rate.rate.is_not_hbl === 1) {
      this.formdata.is_hbl = false
    }
    this.checkService(this.formdata.is_hbl, 'is_hbl')
  },

  methods: {
    getWarehouseData() {
      this.checkDeliveryAccess()
      const uri = this.$api + '/api/v1/getClientReferenceData'
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };

      const params = {
        reference_name: 'ref_client_warehouse',
        client_id: this.$_getsetting("client_id"),
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0){
          this.warehousData = JSON.parse(data.message)
        }
      });
    },

    fillFromStore() {
      if (this.storeData) {
        Object.values(this.elements).forEach(element => {
          this.formdata[element.name] = this.storeData[element.name];
        });

        if (this.formdata.is_car) {
          Object.values(this.autodelivery).forEach(element => {
            this.formdata[element.name] = this.storeData[element.name];
          });
        }
        this.formdata.delivery_to = this.storeData.delivery_to
        this.formdata.warehouse_address = this.storeData.warehouse_address
        this.selected_warehouse = this.storeData.warehouse_address || [];

        this.formdata.pickup_address = this.storeData.pickup_address
        this.formdata.cont_cnt = this.storeData.cont_cnt
      }
    },

    checkDeliveryAccess() {
      const uri = this.$api + this.$methods.getSettingByName.url
      const headers = {
        headers: {
          "Accept": "application/json",
        }
      };

      const params = {
        setting_name: "is_use_avtodispetcher"
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.deliveryAccess = JSON.parse(data.message).is_use_avtodispetcher
        }
      });
    },

    getCitiesTo(val) {
      this.$postapi(this.$apiCity + this.$getters.getCityList.uri, {
        method: this.$getters.getCityList.name,
        place_name: val
      }).then(data => {
        if (data.error == 0) {
          this.cities_to = JSON.parse(data.message)
        }
      });
    },

    checkService(value, name) {
      let element = {
        name: name,
        value: value,
      }
      this.$emit('checkService', element)
    },

    fillWarehouse(data) {
      this.formdata.warehouse_name = data.whs_name
      this.formdata.warehouse_address = data.whs_addr
      this.formdata.warehouse_contact = data.whs_contact
      this.formdata.warehouse_phone = validPhone(data.whs_phone) ? data.whs_phone : '';
      this.formdata.warehouse_recip_name = data.whs_recip_name

      this.$emit('validateForm')
    },

    OnEditChange() {
      return this.formdata;
    },

    validate() {
      this.valid = true
      if (this.formdata.is_car === true) {
        !this.$refs.addressInput.validate() ? this.valid = false : ''

        const inputs = this.$refs.formInputs;
        inputs.forEach(input => {
          const isValid = input.validate()
          if (!isValid) {
            this.valid = false
          }
        });

        if (this.select_city === false) {
          this.delivery_to_error = 'Выберите город из списка'
          this.valid = false
        }
      }

      if (this.formdata.is_party === true || this.formdata.is_exw === true) {
        const inputs = this.$refs.extrasFormInput;
        inputs.forEach(input => {
          if (input.$el && input.$el.offsetParent !== null) {
            const isValid = input.validate();
            if (!isValid) {
              this.valid = false;
            }
          }
        });
      }
      return this.valid
    },

    setSelectCity() {
      this.select_city = false
    },

    resetCities() {
      this.delivery_to_error = null
      this.select_city = true
      this.cities_to = []
    },

    isDisabled(element) {
      if (element.name === 'is_vtt') {
        return Number(this.rate.rate.is_not_vtt) === 1
      } else if (element.name === 'is_hbl') {
        return Number(this.rate.rate.is_not_hbl) === 1
      } else return false
    },

    onCreatingNew(value) {
      this.isCreatingNew = value;
      this.selected_warehouse = []
      this.formdata.warehouse_name = ''
      this.formdata.warehouse_phone = ''
      this.formdata.warehouse_contact = ''

    },
  },
}
</script>

<template>
  <v-form ref="extrasForm" class="cv-grid-div" v-model="valid">
    <div class="checkbox-container" v-for="(element, index) in elements" :key="index">
      <AppCheckbox
        class="first-column"
        :label="element.caption"
        v-model="formdata[element.name]"
        :disabled="isDisabled(element)"
        :description="isDisabled(element) ? 'Сервис недоступен' : ''"
        @input="checkService($event, element.name), $emit('validateForm')"
      />
      <div class="second-column" >
        <div v-if="element.children && formdata[element.name] === true">
          <AppInput
            v-if="element.children.type !== 'select'"
            ref="extrasFormInput"
            v-model="formdata[element.children.name]"
            :label="element.children.caption"
            :rules="element.children.rules"
            required
            @input="$emit('validateForm')"
          />
          
        </div>
      </div>
    </div>
    <div class="autodelivery-container" v-show="formdata.is_car === true">
      <AppDropDown
        class="autodelivery__city"
        ref="deliveryInput"
        required
        :options="cities_to"
        v-model="formdata.delivery_to"
        :label="elements.is_car.children.caption"
        :rules="elements.is_car.children.rules"
        editable
        hide-icon
        :error-text="delivery_to_error"
        @input="[$emit('getRoute', formdata.delivery_to), resetCities() ]"
        @changeInput="[getCitiesTo($event), setSelectCity()]"
        @blur="select_city === false ? delivery_to_error = 'Выберите город из списка' : ''"
      />
      <AppDropDown
        v-if="warehousData.length > 0"
        :options="warehousData"
        :label="autodelivery.warehouse_address.caption"
        :rules="autodelivery.warehouse_address.rules"
        v-model="selected_warehouse"
        item-text="whs_addr"
        class="checkbox-container__input"
        ref="addressInput"
        required
        return-object
        editable
        autocomplete
        @input="fillWarehouse($event)"
        @changeInput="formdata.warehouse_address = $event, $emit('validateForm')"
        createBtn
        @onCreatingNew="onCreatingNew"
        :isCreatingNew="isCreatingNew"
        :hideIcon="isCreatingNew"
      />
      <AppInput
        v-else
        v-model="formdata.warehouse_address"
        :label="autodelivery.warehouse_address.caption"
        :rules="autodelivery.warehouse_address.rules"
        ref="addressInput"
        required
      />
      <AppInput
        v-for="element in filteredObj"
        :key="element.name"
        :label="element.caption"
        :rules="element.rules"
        :phoneMask="element.name === 'warehouse_phone'"
        v-model="formdata[element.name]"
        ref="formInputs"
        required
        @input="$emit('validateForm')"
      />
      <AppButton
        type="button"
        label="Сбросить изменения"
        color="grey"
        class="autodelivery__btn"
        @click="onCreatingNew(false)"
        v-if="isCreatingNew"
      />
    </div>

  </v-form>
</template>

<style lang="scss" scoped>
.cv-grid-div {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  align-items: flex-start;
  align-content: center;
  grid-column-gap: 32px;
  justify-items: flex-start;

  @media (max-width: 620px) {
    flex-direction: column;
  }
}

.autodelivery-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 14px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  background-color: var(--grey-5);
  padding: 16px;
  border-radius: 16px;

  .autodelivery__city {
    grid-column: 1 / 1;
    grid-row: 1;
    width: 100%;
  }

  div:nth-child(2){
    grid-column: 2 / 1;
  }

  div:nth-child(3) {
    grid-column: 2 / 2;
  }

  div:nth-child(4) {
    grid-column: 2 / 1;
  }

  div:nth-child(5) {
    grid-column: 2 / 2;
  }

  .autodelivery__btn{
    grid-column: 2 / 3;
    max-width: 210px;
    margin-left: auto;
  }

  @media (max-width: $mobile-width){
    grid-template-columns: 1fr;

    div{
      grid-column: unset !important;
    }

    .autodelivery__btn{
      max-width: unset;
    }
  }
}

.checkbox-container {
  display: flex;
  height: 70px;
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;

  @media (max-width: $mobile-width){
    flex-direction: column;
    align-items: flex-start;
    height: unset;
    min-height: 70px;
  }

  &__input {
    width: 100%;
    position: relative;
  }

  .first-column {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .second-column {
    flex-basis: 0;
    flex-grow: 1;
  }
}
</style>
