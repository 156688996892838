<script>
import { order_request_form_new } from "../jsons/order-request-form-new";
import {bus} from "@/router/bus";

import AppInput from "@/components/ui/AppInput.vue";
import AppDropdown from "@/components/ui/AppDropDown.vue";
import AppTextarea from "@/components/ui/AppTextarea.vue";
import AppAlertPlate from "@/components/ui/AppAlertPlate.vue";

export default {
  name: 'MainInfoForm',

  props: {
    rate: { type: [Array, Object], required: true },
    // storeData: { type: Object, default: null },
    order_key: Number
  },

  components: {
    AppDropdown,
    AppInput,
    AppTextarea,
    AppAlertPlate
  },

  data() {
    return {
      args: {},
      elements: order_request_form_new.main_info,
      schedule: [],
      agentData: [],
      security_price: null,
      showLoader: false,
      valid: false,
      selected_service: [],


      formdata: {
        place_from_name: "",
        cont_type: "",
        place_to_name: "",
        station_name: "",
        line_name: "",
        port_name: "",
        across_place_name_ext: "",
        exp_name: "",
        drop_place: "",
        client_id: null,
        rate: null,
        order_num: null,
        agent_id: null,
        agent_name: null,
        schedule: null,
        special_condition: "",
      }
    }
  },

  computed: {
    findRailway() {
      if (this.args && this.args.rate && this.args.rate.detail) {
        return this.args.rate.detail.some(service => service.type_id === 214)
      } else {
        return false
      }
    },
  },

  // watch: {
  //   storeData: {
  //     handler() {
  //       this.fillFromStore()
  //     },
  //     deep: true,
  //   },
  // },

  mounted() {
    this.fillFormdata()
    this.getShipScheduleByKey()
    this.getSecurityServiceInfo()
  },

  methods: {
    fillFormdata() {
      this.args = this.rate
      // this.formdata.order_num = this.order_key
      this.formdata.place_from_name = this.args.rate.base_place_from_name
      this.formdata.cont_type = this.args.rate.unit_code
      this.formdata.place_to_name = this.args.rate.base_place_to_name
      this.formdata.station_name = this.args.rate.station_name
      this.formdata.line_name = (this.args.rate.is_hide_line == 0 || this.formdata.client_id == -1) ? this.args.rate.line_name : 'Vedexx'
      this.formdata.port_name = this.args.rate.place_across_name
      this.formdata.across_place_name_ext = this.args.rate.across_place_name_ext
      this.formdata.exp_name = this.args.rate.charterer_name
      this.formdata.drop_place = this.args.rate.base_place_to_name
    },

    fillFromStore() {
      if (this.storeData) {
        this.formdata.special_condition = this.storeData.special_condition
        this.formdata.order_num = this.storeData.order_num
        this.formdata.schedule = this.storeData.schedule || null;
      }
    },

    validate() {
      this.valid = true
      if (!this.$refs.special_condition.validate() || !this.$refs.order_num.validate()) {
        this.valid = false
      }
      if (this.schedule.length > 0) {
        this.valid = this.$refs.inputSchedule.validate()
      }
      return this.valid
    },

    getShipScheduleByKey() {
      this.showLoader = true
      const uri = this.$api + this.$methods.getShipScheduleByKey.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        key: this.key,
        on_date: this.on_date,
        unit_type: 0
      }
      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error == 0) {
          this.schedule = JSON.parse(
            data.message
              .replace(/:"f",/gm, `:false,`)
              .replace(/:"t",/gm, `:true,`)
          );
          this.sortSchedule()
          this.showLoader = false
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    sortSchedule() {
      let filteredArray = this.schedule.filter(obj => this.isDate7DaysAhead(obj.sea_start_date));
      this.schedule = filteredArray
      this.$emit('validateForm')
    },

    isDate7DaysAhead(date) {
      let currentDate = new Date();
      let sevenDaysLater = new Date(currentDate);
      sevenDaysLater.setDate(currentDate.getDate() + 7);
      return new Date(date) > sevenDaysLater;
    },

    getSecurityServiceInfo() {
      if (this.findRailway == true) {
        const uri = this.$api + this.$methods.getSecurityServiceInfo.url
        const token = this.$_getsetting('token')
        const headers = {
          headers: {
            "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
          }
        };

      const params = {
        key: this.args.rate.key_orig,
        client_id: this.$_getsetting('client_id'),
      }
      this.$postapi(uri, params, headers)
      .then(data => {
          if (data.error == 0){
            this.selected_service = JSON.parse(data.message)
            this.security_price = this.selected_service.cost
            this.$emit('addService', this.selected_service)
          }
        });
      }
    },

    OnEditChange() {
      return this.formdata;
    },
  },
}
</script>

<template>
  <v-form ref="mainInfoForm" class="main-form">
    <div class="cv-grid-div mt-4">
      <AppInput
        class="main-form__input"
        v-model="formdata.place_from_name"
        :label="elements.place_from_name.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.cont_type"
        :label="elements.cont_type.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.place_to_name"
        :label="elements.place_to_name.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.line_name"
        :label="elements.line_name.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.port_name"
        :label="elements.port_name.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.exp_name"
        :label="elements.exp_name.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.drop_place"
        :label="elements.drop_place.caption"
        disabled
      />
      <AppInput
        class="main-form__input"
        v-model="formdata.order_num"
        ref="order_num"
        :rules="elements.order_num.rules"
        :label="elements.order_num.caption"
      />
      <div class="main-form__input">
        <AppDropdown
          v-if="schedule.length > 0 || showLoader"
          class="main-form__input"
          :options="schedule"
          return-object
          :loading="showLoader"
          :rules="elements.schedule.rules"
          v-model="formdata.schedule"
          ref="inputSchedule"
          label="Судно"
          required
          :item-text="item => item.vassel_name + ', '
          + new Date(item.sea_start_date).toLocaleDateString('ru', {
            month: 'long',
            day: 'numeric'
          })"
          item-value="id"
          placeholder="Выберите судно"
          @input="$emit('validateForm')"
        />
      </div>
      <AppTextarea
        class="main-form__input"
        ref="special_condition"
        v-model="formdata.special_condition"
        :rules="elements.special_condition.rules"
        :label="elements.special_condition.caption"
        :placeholder="elements.special_condition.placeholder"
        :rows="5"
      />
    </div>

    <div class="alert-block"></div>

    <AppAlertPlate class="alert-block" v-if="findRailway" color="var(--blue-70)" background-color="var(--blue-10)">
      <p slot="title" v-if="Number(security_price) !== 0">Стоимость охраны по жд: {{security_price}} &#8381;</p>
      <p slot="title" v-else>Точная стоимость охраны требует уточнения с подрядчиком</p>
      <p slot="description"> Если ваш груз по коду ТНВЭД подлежит охране по жд, к ставке будет добавлен соответствующий тариф <span class="alert-block__kfk-description" v-if="rate.rate.is_kfk"> Если вам требуется прохождение КФК - необходимо уточнить степень риска по коду вашего товара</span></p>
    </AppAlertPlate>
  </v-form>
</template>

<style lang="scss" scoped>

.main-form {
  min-height: 650px;

  &__input {
    width: 100%;
    position: relative;
  }
}

.cv-grid-div {
  display: grid;
  z-index: 400;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 4px;
  justify-items: flex-start;

  &:last-child {
    padding-bottom: 235px;
  }

  @media (max-width: $mobile-width) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.cv-row-div {
  display: flex;
  margin: 0;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.cv-row-margin {
  top: -20px;
  margin: -30px auto -17px;
  justify-content: space-between;

  @media (max-width: 620px) {
    justify-content: start;
  }
}

.alert-block {
  margin-bottom: 24px;
}
</style>
